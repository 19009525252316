import React from 'react'
import { useHistory } from 'react-router-dom'
import { TFunction, withTranslation } from 'react-i18next'
import LoadingBar from 'react-top-loading-bar'
import styled from 'styled-components'
import { useStore } from '../context'
import { useMessages } from '../context/messages'
import { unsetLayout } from '../context/actionReducer'
import { UIService } from '../services'
import { generateLayout } from '../layouts'
import { ROUTES } from '../constants'
import {
  StyledColumn,
  StyledGrid,
  HistoryStyledWrapper,
  StyledTitleContainer,
  StyledHeader,
  StyledDesc,
  StyledNavIcons,
  StyledIconButton,
  LinkButton,
} from '../components/commonStyles'
import { StyledCard } from '../layouts/styles'
import { ReactComponent as HomeIcon } from '../theme/icons/home.svg'
import {
  getQueryObject,
  isEmptyObject,
  labelForName,
  sizes1,
  sizes2,
} from '../helpers'
import { toLocaleDateString } from '../helpers/date'
import useQuery from '../helpers/useQuery'

const StyledHistoryCard = styled(StyledCard)`
  display: flex;
  justify-content: space-between;
`

const LoadMoreContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  margin-bottom: 30px;
`

const LoadMoreBtn = styled(LinkButton)`
  margin: 0 auto;
  color: var(--pages-mainContainer-primaryTextColor);
  font-size: 14px;
  font-weight: 500;
`

const StyledLoadingBar = styled(LoadingBar)`
  div {
    div {
      margin-left: -1rem;
    }
  }
`

interface HistoryProps {
  location: {
    state: {
      pageDesc: string | null
      pageTitle: string | null
    }
  }
  t: TFunction
}

const History: React.FC<HistoryProps> = (props) => {
  const history = useHistory()
  const { t } = props
  const [state, dispatch] = useStore()
  const ref = React.useRef(null)

  const [items, setItems] = React.useState([])
  const [grouped, setGrouped] = React.useState({})
  const [dates, setDates] = React.useState([])
  const [hasMore, setHasMore] = React.useState(
    items.length === 5 ? true : false
  )
  const [lastTimestamp, setLastTimestamp] = React.useState('')
  //const [isLoading, setIsLoading] = React.useState(false)

  const { addToast } = useMessages()

  const { globalLayout } = state

  const [layout, setLayout] = React.useState({
    side: globalLayout?.side,
    top: globalLayout?.top,
  })

  const query = useQuery()
  const params = getQueryObject(query)

  // Load history items
  React.useEffect(() => {
    const currentTimestamp = items[items.length - 1]?.createdAt || ''

    if (!currentTimestamp || currentTimestamp === lastTimestamp) {
      ref.current && ref.current.continuousStart()
      //setIsLoading(true)
      UIService.getUserHistory(currentTimestamp)
        .then((data) => {
          if (data && data.length > 0) {
            setItems([...items, ...data])
          }
          setHasMore(data.length === 5 ? true : false)
        })
        .catch(() => {
          t([
            'history.serviceErrorMessage',
            'errors.genericServiceErrorMessage',
          ])
        })
        .finally(() => {
          ref.current && ref.current.complete()
          //setIsLoading(false)
        })
    }
  }, [addToast, items, lastTimestamp, t])

  // Group history items by date every time we load new history items
  React.useEffect(() => {
    const groupedd = items.reduce((arr, item) => {
      const dt = new Date(item.createdAt)
      const dd = toLocaleDateString(dt, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })

      if (arr[dd]) {
        arr[dd].push(item.body)
      } else {
        arr[dd] = [item.body]
      }

      return arr
    }, [])

    const dts = Object.keys(groupedd)
    if (dts) {
      setGrouped(groupedd)
      setDates(dts)
    }
  }, [items])

  // Load layout in case we visit history page directly
  React.useEffect(() => {
    if (!layout.side) {
      UIService.fetchUserOptions(params)
        .then((data) => {
          setLayout({
            side: data.side,
            top: data.top,
          })
          if (!isEmptyObject(params)) history.replace({ search: '' })
        })
        .catch((e) =>
          addToast(
            'error',
            t([
              'history.userOptionsErrorMessage',
              'errors.genericServiceErrorMessage',
            ])
          )
        )
    }
  }, [layout.side, addToast, params, history, t])

  const handleHomeButton = () => {
    dispatch(unsetLayout())
    history.push(ROUTES.DASHBOARD)
  }

  // TODO revisit this
  let sizes = sizes1
  if (!layout.side || (layout.side && layout.side.length === 0)) {
    sizes = sizes2
  }

  const { labels } = layout?.top
    ? layout.top[0].buttons[0].action
    : { labels: [] }
  const pageTitle = labelForName('pageTitle', labels)
  const pageDesc = labelForName('pageDesc', labels)

  return (
    <React.Fragment>
      <StyledLoadingBar
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-loader-pageColor'
        )}
        height={8}
        ref={ref}
      />
      <HistoryStyledWrapper align="left">
        <StyledGrid halign="center">
          <StyledColumn size={sizes.container}>
            <StyledNavIcons>
              <StyledIconButton onClick={handleHomeButton}>
                <HomeIcon />
              </StyledIconButton>
            </StyledNavIcons>
            <StyledTitleContainer>
              <StyledGrid>
                <StyledColumn size={sizes.title}>
                  <div>
                    {pageTitle && (
                      <StyledHeader
                        dangerouslySetInnerHTML={{
                          __html: pageTitle,
                        }}
                      />
                    )}
                    {pageDesc && (
                      <StyledDesc
                        dangerouslySetInnerHTML={{
                          __html: pageDesc,
                        }}
                      />
                    )}
                  </div>
                </StyledColumn>
              </StyledGrid>
            </StyledTitleContainer>
            <StyledGrid>
              <StyledColumn size={sizes.main} style={{ margin: '0 auto' }}>
                {dates.length > 0
                  ? dates.map((dt, idx) => {
                      return (
                        <div style={{ width: '100%' }} key={idx}>
                          <div
                            style={{
                              textAlign: 'right',
                              marginBottom: '8px',
                              fontSize: '12px',
                              fontStyle: 'italic',
                            }}
                          >
                            {t('history.submissionDate', '')} {dt}
                          </div>
                          <div>
                            {grouped[dt].map((item: any, key: React.Key) => (
                              <StyledHistoryCard key={key}>
                                <div
                                  dangerouslySetInnerHTML={{ __html: item }}
                                />
                              </StyledHistoryCard>
                            ))}
                          </div>
                        </div>
                      )
                    })
                  : t('history.noHistoryItemsMessage', '')}

                {hasMore && (
                  <LoadMoreContainer>
                    <LoadMoreBtn
                      className="btn-load-more"
                      onClick={() =>
                        setLastTimestamp(
                          items[items.length - 1]?.createdAt || ''
                        )
                      }
                    >
                      {t('history.loadMoreLabel', '')}
                    </LoadMoreBtn>
                  </LoadMoreContainer>
                )}
              </StyledColumn>
              <StyledColumn size={sizes.side}>
                {layout.side && layout.side.map((item) => generateLayout(item))}
              </StyledColumn>
            </StyledGrid>
          </StyledColumn>
        </StyledGrid>
      </HistoryStyledWrapper>
    </React.Fragment>
  )
}

export default withTranslation()(History)
