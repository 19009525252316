import { ColorsT } from '../theme/types'
import { StyledCard, StyledCardTitle, StyledCardDesc } from './styles'

interface L103Props {
  items: {
    title: string
    description: string
    image: string
  }
  colors: ColorsT
  theme?: any
}

export default function L103(props: L103Props) {
  const { title, description, image } = props.items

  return (
    <StyledCard
      customTheme={props.theme}
      className="L103_StyleCard"
      style={{ display: 'flex', flexBasis: '100%' }}
      {...props.colors}
    >
      <div style={{ width: '100%' }}>
        {title && (
          <StyledCardTitle dangerouslySetInnerHTML={{ __html: title }} />
        )}
        {description && (
          <StyledCardDesc dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {image && (
          <div style={{ textAlign: 'right' }}>
            <img src={image} alt="css-no-options" />
          </div>
        )}
      </div>
    </StyledCard>
  )
}
