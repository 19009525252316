import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { authService } from '../services'
import { useStore } from '../context'
import { unsetLayout } from '../context/actionReducer'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { LOCAL_STORAGE_KEYS, ROUTES } from '../constants'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import * as Yup from 'yup'
import {
  StyledFormButton,
  Heading,
  InputFieldContainer,
  StyledColumn,
  StyledWrapper,
  StyledGrid,
  Caption,
  StyledForm,
  StyledFlexRow,
  StyledCheckBoxLabel,
  LinkButton,
  StyledLoadingBar,
} from '../components/commonStyles'
import { getErrorKeys, getToken, isLoggedIn, removeToken } from '../helpers'
import { TFunction } from 'i18next'
import { Field, Formik } from 'formik'

interface OtpProps {
  config: any
  t: TFunction
}

const Otp: React.FC<OtpProps> = (props) => {
  const { t, config } = props
  const history = useHistory()

  const { auth } = authService
  if (isLoggedIn(auth)) {
    history.push(ROUTES.DASHBOARD)
  }

  const { state } = useLocation<{ channel?: 'email' | 'phone' }>()
  if (!state) {
    history.push(ROUTES.OTP)
  }

  const ref = React.useRef(null)
  const { addToast } = useMessages()

  const [, dispatch] = useStore()
  React.useEffect(() => {
    dispatch(unsetLayout(true))
  }, [dispatch])

  const logout = () => {
    authService.logout().then(() => history.push(ROUTES.LOGIN))
  }

  const handleSubmit = (values, { setSubmitting }) => {
    ref.current && ref.current.continuousStart()
    authService
      .verifyOtp(values.otp, values.rememberDevice)
      .then(() => {
        setSubmitting(false)
        ref.current && ref.current.complete()
        addToast(MESSAGE_STATUS.SUCCESS, t(['otp.200', '']))
        const redirectUrl = getToken(LOCAL_STORAGE_KEYS.REDIRECT_URL)
        removeToken(LOCAL_STORAGE_KEYS.REDIRECT_URL)
        history.push(redirectUrl || ROUTES.DASHBOARD)
      })
      .catch((e) => {
        setSubmitting(false)
        ref.current && ref.current.complete()
        let errorMessage = t('errors.genericErrorMessage', '')
        if (e.response) {
          const injectValues = {}
          const { data } = e.response

          if (data?.details?.unlocksInMins) {
            injectValues['unlocksInMins'] = data?.details?.unlocksInMins
          }

          errorMessage = data
            ? t(getErrorKeys('errors', data), injectValues)
            : t(['otp.errorMessage', 'errors.genericErrorMessage', ''])
        }

        addToast(MESSAGE_STATUS.ERROR, errorMessage)

        if (e?.response?.status === 423) logout()
      })
  }

  const resendOtp = () => {
    authService
      .sendOtp(state.channel)
      .then((res) => {
        addToast(MESSAGE_STATUS.SUCCESS, t('otp.resendSuccess', ''))
      })
      .catch((err) => {
        let errorMessage = t('errors.genericErrorMessage', '')
        if (err.response) {
          const injectValues = {}
          const { data } = err.response

          if (data?.details?.unlocksInMins) {
            injectValues['unlocksInMins'] = data?.details?.unlocksInMins
          }

          errorMessage = data
            ? t(getErrorKeys('errors', data), injectValues)
            : t(['mfaChannel.errorMessage', 'errors.genericErrorMessage', ''])
        }

        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  const validationSchema = Yup.object({
    otp: Yup.string().required(t(['forms.requiredField'])),
    rememberDevice: Yup.boolean(),
  })

  return (
    <React.Fragment>
      <StyledLoadingBar
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-loader-pageColor'
        )}
        height={8}
        ref={ref}
      />
      <StyledWrapper align="center" margin="125px auto">
        <StyledGrid halign="center">
          <StyledColumn
            size={{ md: 4 / 8, lg: 3 / 12 }}
            halign="center"
            direction="column"
          >
            <Heading
              dangerouslySetInnerHTML={{
                __html: t('otp.heading', ''),
              }}
            />
            <Caption
              dangerouslySetInnerHTML={{
                __html: t('otp.caption', ''),
              }}
            />
            <Formik
              initialValues={{ otp: '', rememberDevice: false }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <StyledForm onSubmit={formik.handleSubmit}>
                  <InputFieldContainer>
                    <InputField
                      id="otp"
                      label={t('otp.codeLabel', '')}
                      autocomplete="one-time-code"
                      type="text"
                      formik={formik}
                    />
                  </InputFieldContainer>

                  {config?.mfaRememberDeviceEnabled && (
                    <StyledFlexRow>
                      <Field
                        type="checkbox"
                        id="rememberDevice"
                        name="rememberDevice"
                        checked={formik.values.rememberDevice}
                        style={{
                          accentColor:
                            'var(--pages-mainContainer-primaryTextColor)',
                        }}
                      />
                      <StyledCheckBoxLabel htmlFor="rememberDevice">
                        {t('otp.rememberThis', '')}
                      </StyledCheckBoxLabel>
                    </StyledFlexRow>
                  )}

                  <div>
                    <StyledFormButton
                      type="submit"
                      id="submit"
                      disabled={!(formik.isValid && formik.dirty)}
                    >
                      {formik.isSubmitting ? (
                        <Loader />
                      ) : (
                        <span>{t('otp.otpButton', '')}</span>
                      )}
                    </StyledFormButton>
                  </div>

                  <LinkButton
                    id="resend-otp-btn"
                    className="buttons-linkColor"
                    onClick={resendOtp}
                  >
                    {t('otp.resendCodeButton', '')}
                  </LinkButton>
                </StyledForm>
              )}
            </Formik>
          </StyledColumn>
        </StyledGrid>
      </StyledWrapper>
    </React.Fragment>
  )
}

export default withTranslation()(Otp)
