import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { withTranslation } from 'react-i18next'
import { authService } from '../services'
import { useStore } from '../context'
import { unsetLayout } from '../context/actionReducer'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { LOCAL_STORAGE_KEYS, ROUTES } from '../constants'
import InputField from '../components/InputField'
import Loader from '../components/Loader'

import {
  StyledFormButton,
  Heading,
  InputFieldContainer,
  LinkButton,
  StyledColumn,
  StyledWrapper,
  StyledGrid,
  StyledForm,
  LoginFlowText,
  StyledFlexColumn,
  StyledLoadingBar,
} from '../components/commonStyles'
import { getErrorKeys, getToken, isLoggedIn, removeToken } from '../helpers'
import { TFunction } from 'i18next'
import { AxiosError, AxiosResponse } from 'axios'

interface Values {
  username: string
  password: string
}

interface LoginProps {
  t: TFunction
  location: any
}

const Login: React.FC<LoginProps> = (props) => {
  const { t } = props
  const ref = React.useRef(null)

  const history = useHistory()
  const { addToast } = useMessages()
  const { auth, deviceId, refreshCache } = authService

  const [, dispatch] = useStore()
  React.useEffect(() => {
    dispatch(unsetLayout(true))
  }, [dispatch])

  React.useEffect(() => {
    // Redirect coming from 401 refresh
    if (window.history.state?.expired) {
      addToast(
        MESSAGE_STATUS.ERROR,
        t(['errors.sessionExpired', 'errors.genericErrorMessage', ''])
      )
    }
  }, [addToast, t])

  const validationSchema = Yup.object({
    username: Yup.string().required(t(['forms.requiredField', ''])),
    password: Yup.string().required(t(['forms.requiredField', ''])),
  })

  const handleSubmit = (
    values: Values,
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    ref.current && ref.current.continuousStart()
    authService
      .login(values.username, values.password, deviceId)
      .then((res) => {
        setSubmitting(false)
        if (res.data?.mfaRequired) {
          history.push({
            pathname: ROUTES.OTP,
            state: { otpChannels: res.data?.otpChannels },
          })
        } else {
          ref.current && ref.current.complete()
          addToast(MESSAGE_STATUS.SUCCESS, t(['login.200', '']))
          const redirectUrl = getToken(LOCAL_STORAGE_KEYS.REDIRECT_URL)
          removeToken(LOCAL_STORAGE_KEYS.REDIRECT_URL)
          history.push(redirectUrl || ROUTES.DASHBOARD)
        }
      })
      .catch((e: AxiosError) => {
        setSubmitting(false)
        ref.current && ref.current.complete()
        let errorMessage = t('errors.genericErrorMessage', '')
        if (e.response) {
          const injectValues = {}
          const { data }: AxiosResponse = e.response

          if (data?.details?.unlocksInMins) {
            injectValues['unlocksInMins'] = data?.details?.unlocksInMins
          }

          errorMessage = data
            ? t(getErrorKeys('errors', data), injectValues)
            : t(['login.errorMessage', 'errors.genericErrorMessage', ''])
        }

        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  if (auth && isLoggedIn(auth)) {
    history.push(ROUTES.DASHBOARD)
  } else {
    refreshCache([
      LOCAL_STORAGE_KEYS.REDIRECT_URL,
      LOCAL_STORAGE_KEYS.CONFIG,
      LOCAL_STORAGE_KEYS.DEVICE_ID,
    ])
  }

  return (
    <React.Fragment>
      <StyledLoadingBar
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-loader-pageColor'
        )}
        height={8}
        ref={ref}
      />
      <StyledWrapper align="center" margin="125px auto">
        <StyledGrid halign="center">
          <StyledColumn
            size={{ md: 4 / 8, lg: 3 / 12 }}
            halign="center"
            direction="column"
          >
            <Heading
              className="pages-mainContainer-secondaryTextColor"
              dangerouslySetInnerHTML={{
                __html: t('login.heading', ''),
              }}
            />

            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              {(formik) => (
                <StyledForm onSubmit={formik.handleSubmit}>
                  <InputFieldContainer>
                    <InputField
                      id="username"
                      label={t('login.usernameLabel', '')}
                      type="text"
                      formik={formik}
                    />
                    <InputField
                      id="password"
                      label={t('login.passwordLabel', '')}
                      type="password"
                      formik={formik}
                    />
                  </InputFieldContainer>

                  <Link to={ROUTES.FORGOT_PSSW}>
                    <LinkButton id="forgot-btn" className="buttons-linkColor">
                      {t('login.forgotButtonText', '')}
                    </LinkButton>
                  </Link>

                  <div>
                    <StyledFormButton
                      type="submit"
                      id="loginButton"
                      disabled={!(formik.isValid && formik.dirty)}
                      className="buttons-backgroundColor buttons-textColor buttons-borderColor"
                    >
                      {formik.isSubmitting ? (
                        <Loader />
                      ) : (
                        <span>{t('login.loginButton', '')}</span>
                      )}
                    </StyledFormButton>
                  </div>

                  <StyledFlexColumn>
                    <LoginFlowText>{t('login.registerText', '')}</LoginFlowText>
                    <Link to={ROUTES.REGISTER}>
                      <LinkButton
                        id="register-btn"
                        className="buttons-linkColor"
                        margin="4px 0"
                      >
                        {t('login.registerButton', '')}
                      </LinkButton>
                    </Link>
                  </StyledFlexColumn>
                </StyledForm>
              )}
            </Formik>
          </StyledColumn>
        </StyledGrid>
      </StyledWrapper>
    </React.Fragment>
  )
}

export default withTranslation()(Login)
