import React from 'react'
import styled from 'styled-components'
import { useStore } from '../context'
import { next } from '../context/actionReducer'
import actionCodes from '../context/actionCodes'
import { ColorsT, ThemeT } from '../theme/types'
import { getToken, setToken, themeForText } from '../helpers'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { useMessages } from '../context/messages'
import Modal, {
  ModalLoadButton,
  ModalSubtitle,
  ModalTitle,
  ClearData,
  LastModifiedLabel,
} from '../components/Modal'
import merge from 'ts-deepmerge'
import { UIService } from '../services'
import Loader from '../components/Loader'
import {
  labelForName,
  buttonForName,
  Button,
  Label,
  handleButtonActionTypeClick,
} from '../helpers'
import {
  StyledCardButton,
  StyledCard,
  StyledCardTitle,
  StyledCardDesc,
  StyledCardBoxWrapper,
  StyledCardBoxContainer,
  StyledCardBox,
} from './styles'
import {
  StyledColumn,
  //StyledWrapper,
  StyledGrid,
} from '../components/commonStyles'
import CardBox from '../components/Cards/CardBox'
import { TFunction, withTranslation } from 'react-i18next'

interface StyledDescProps {
  theme: ThemeT
}

const StyledDesc = styled(StyledCardDesc)`
  @media (min-width: ${(props: StyledDescProps) =>
      props.theme.breakpoints.md}px) {
    margin-right: 100px;
  }
`
interface L100Props {
  labels: Label[]
  buttons: Button[]
  colors: ColorsT
  height: number
  setHeight: React.Dispatch<React.SetStateAction<number>>
  theme?: any
  t: TFunction
}

const L100: React.FC<L100Props> = (props) => {
  const [, dispatch] = useStore()
  const { labels, buttons, theme, t } = props

  const [showModal, setShowModal] = React.useState(false)
  const [cacheIsLoading, setCacheIsLoading] = React.useState(false)
  const [headIsLoading, setHeadIsLoading] = React.useState(false)
  const [lastModified, setLastModified] = React.useState('')

  const title = labelForName('title', labels)
  const description = labelForName('description', labels)
  const bottomBoxesTitle = labelForName('bottomBoxesTitle', labels)
  const box1Title = labelForName('box1Title', labels)
  const box1Value = labelForName('box1Value', labels)
  const box1Description = labelForName('box1Description', labels)
  const box2Title = labelForName('box2Title', labels)
  const box2Value = labelForName('box2Value', labels)
  const box2Description = labelForName('box2Description', labels)
  const box3Title = labelForName('box3Title', labels)
  const box3Value = labelForName('box3Value', labels)
  const box3Description = labelForName('box3Description', labels)

  const button1 = buttonForName('button1', buttons)
  const allSteps = button1?.action?.option?.steps
  const step = button1?.action?.option?.steps
    ? button1?.action?.option?.steps[0]?.id
    : 0
  const { addToast } = useMessages()
  const stepItems = button1?.action?.option?.steps
    ? button1?.action?.option?.steps.find((st) => st.id === step).items
    : []

  const loadCache = () => {
    setCacheIsLoading(true)
    const customerActions = actionCodes.getCustomerActions()
    UIService.getCustomerCache(
      customerActions.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    )
      .then((res) => {
        if (res.data) {
          let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
          if (!crnCache) {
            crnCache = {}
          }
          // merge on correct caseId > flowId > flowInstanceId
          let crnFormCache =
            crnCache[customerActions.caseId]?.[customerActions.flowId]?.[
              customerActions.flowInstanceId
            ]
          if (!crnFormCache) {
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: res.data,
                },
              },
            }
          } else {
            const toMerge = merge(crnFormCache, res.data)
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: toMerge,
                },
              },
            }
          }
          setToken(LOCAL_STORAGE_KEYS.CACHE, merge(crnCache, crnFormCache))
        }
      })
      .catch((e) => {
        if (!getToken(LOCAL_STORAGE_KEYS.CACHE)) {
          addToast('error', t('cacheModal.cacheError', ''))
        }
      })
      .finally(() => {
        setCacheIsLoading(false)
        setShowModal(false)
        dispatch(next(button1?.action))
      })
  }

  const clearCache = () => {
    let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
    if (crnCache) {
      delete crnCache[button1?.action.caseId]
      setToken(LOCAL_STORAGE_KEYS.CACHE, crnCache)
    }

    if (stepItems && stepItems.length > 0) {
      const allCodes = allSteps
        .map((crnStep) =>
          crnStep.items
            .map((crnItem) => crnItem.code)
            .filter((x) => x !== undefined)
        )
        .flat()
      let uniqueCodes = allCodes.filter((el, i) => allCodes.indexOf(el) === i)
      actionCodes.clearCodes(button1?.action.caseId, uniqueCodes)
    }
    const customerActions = actionCodes.getCustomerActions()
    UIService.deleteCustomerCacheForCase(
      button1?.action?.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    ).finally(() => {
      setShowModal(false)
      dispatch(next(button1?.action))
    })
  }

  return (
    <StyledCardBox
      style={{ display: 'flex', flexBasis: '100%', marginLeft: '0px' }}
    >
      <Modal show={showModal}>
        <ModalTitle>{t('cacheModal.title', '')}</ModalTitle>
        <ModalSubtitle>{t('cacheModal.subtitle', '')}</ModalSubtitle>
        <div className="row">
          <LastModifiedLabel>
            Information saved {lastModified}
          </LastModifiedLabel>
          <ModalLoadButton onClick={loadCache}>
            {cacheIsLoading ? <Loader /> : t('cacheModal.loadButton', '')}
          </ModalLoadButton>
          <ClearData onClick={clearCache}>
            {t('cacheModal.clearButton', '')}
          </ClearData>
        </div>
      </Modal>
      <StyledCard
        className="L100_StyleCard"
        style={{ display: 'flex', flexBasis: '100%' }}
        {...props.colors}
        customTheme={theme}
      >
        <div style={{ width: '100%' }}>
          {title && (
            <StyledCardTitle
              dangerouslySetInnerHTML={{ __html: title }}
              customTheme={themeForText(title, labels)}
            />
          )}
          {description && (
            <StyledDesc
              dangerouslySetInnerHTML={{ __html: description }}
              customTheme={themeForText(description, labels)}
            />
          )}
          <StyledCardBoxContainer
            customTheme={themeForText(bottomBoxesTitle, labels)}
          >
            {bottomBoxesTitle && (
              <div className="boxes-title">{bottomBoxesTitle}</div>
            )}
            <StyledGrid valign="bottom">
              <StyledColumn size={{ md: 12 / 12 }} gutter="0">
                <StyledCardBoxWrapper>
                  {(box1Title || box1Value || box1Description) && (
                    <CardBox
                      header={box1Title}
                      value={box1Value}
                      desc={box1Description}
                      height={props.height}
                      setHeight={props.setHeight}
                      headerTheme={themeForText(box1Title, labels)}
                      valueTheme={themeForText(box1Value, labels)}
                      descTheme={themeForText(box1Description, labels)}
                    />
                  )}
                  {(box2Title || box2Value || box2Description) && (
                    <CardBox
                      header={box2Title}
                      value={box2Value}
                      desc={box2Description}
                      height={props.height}
                      setHeight={props.setHeight}
                      headerTheme={themeForText(box2Title, labels)}
                      valueTheme={themeForText(box2Value, labels)}
                      descTheme={themeForText(box2Description, labels)}
                    />
                  )}
                  {(box3Title || box3Value || box3Description) && (
                    <CardBox
                      header={box3Title}
                      value={box3Value}
                      desc={box3Description}
                      height={props.height}
                      setHeight={props.setHeight}
                      headerTheme={themeForText(box3Title, labels)}
                      valueTheme={themeForText(box3Value, labels)}
                      descTheme={themeForText(box3Description, labels)}
                    />
                  )}
                </StyledCardBoxWrapper>
              </StyledColumn>
              <StyledColumn size={{ md: 12 / 12 }} gutter="0" halign="flex-end">
                {button1?.text && (
                  <StyledCardButton
                    className=""
                    onClick={() =>
                      handleButtonActionTypeClick(
                        button1,
                        dispatch,
                        setHeadIsLoading,
                        setLastModified,
                        setShowModal,
                        addToast,
                        t
                      )
                    }
                    customTheme={button1.theme}
                  >
                    {headIsLoading ? <Loader /> : button1.text}
                  </StyledCardButton>
                )}
              </StyledColumn>
            </StyledGrid>
          </StyledCardBoxContainer>
        </div>
      </StyledCard>
    </StyledCardBox>
  )
}

export default withTranslation()(L100)
