import React, { useEffect, useState } from 'react'
import { TFunction, withTranslation, useTranslation } from 'react-i18next'
import useQuery from '../helpers/useQuery'
import {
  Caption,
  Heading,
  InputFieldContainer,
  LinkButton,
  StyledColumn,
  StyledFlexColumn,
  StyledForm,
  StyledFormButton,
  StyledGrid,
  StyledWrapper,
} from '../components/commonStyles'
import { Formik } from 'formik'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import { useHistory } from 'react-router-dom'
import { MESSAGE_STATUS, useMessages } from '../context/messages'
import * as Yup from 'yup'
import { LOCAL_STORAGE_KEYS, OTPLoginMethods, ROUTES } from '../constants'
import { getErrorKeys, isLoggedIn } from '../helpers'
import { authService } from '../services'
import { useStore } from '../context'
import { unsetLayout } from '../context/actionReducer'
import { RotatingLines } from 'react-loader-spinner'
import { FiPhone } from 'react-icons/fi'
import { HiOutlineUser } from 'react-icons/hi'
import { TbMail } from 'react-icons/tb'
import IconButton from '../components/IconButton'
import styled from 'styled-components'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { PortalConfig } from '../helpers/useConfig'

const StyledPhoneInput = styled.div`
  .react-tel-input {
    .flag-dropdown.open {
      z-index: 0;
    }

    .selected-flag {
      background: var(--pages-mainContainer-backgroundColor);
      border: 1px solid var(--pages-mainContainer-primaryTextColor);
      border-right: 0;
      border-left: 0;
      border-top: 0;
      border-radius: 0;
    }

    .special-label {
      background: var(--pages-mainContainer-backgroundColor);
      left: 0;
      padding-left: 11px;
      font-size: 10px;
    }

    .form-control {
      background: var(--pages-mainContainer-backgroundColor);
      border: 1px solid var(--pages-mainContainer-primaryTextColor);
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;

      &:focus {
        border-color: var(--pages-mainContainer-primaryTextColor);
        box-shadow: 0 0 0 1px transparent;
      }
    }
  }
`

const getLoginMethod = (query: URLSearchParams, methods: string[]) => {
  for (let methodKey in OTPLoginMethods) {
    if (query.has(methodKey) && methods?.includes(OTPLoginMethods[methodKey]))
      return OTPLoginMethods[methodKey]
  }

  return ''
}

const extractQueryCredentials = (query: URLSearchParams, type: string = '') => {
  const key = Object.keys(OTPLoginMethods)?.find(
    (key) => OTPLoginMethods[key] === type
  )
  return query.get(key)
}

const phoneRegex = /^\+[0-9]{10,}$/

interface Props {
  config?: PortalConfig
  t: TFunction
}

const OtpLogin: React.FC<Props> = ({ t, config }) => {
  const { i18n } = useTranslation()

  const methods = Object.keys(config?.otpLoginMethods)

  const currentLanguage = i18n.language
  const countryCode =
    currentLanguage?.split('_')?.[1]?.toLocaleLowerCase() || 'gb'

  const query = useQuery()
  const queryType = getLoginMethod(query, methods)
  const queryId = extractQueryCredentials(query, queryType)

  const [id, setId] = useState(queryId)
  const [type, setType] = useState(queryType || '')

  const buttonRef = React.useRef(null)

  const history = useHistory()
  const { addToast } = useMessages()

  const { auth, refreshCache } = authService

  const [, dispatch] = useStore()
  React.useEffect(() => {
    dispatch(unsetLayout(true))
  }, [dispatch])

  React.useEffect(() => {
    // Redirect coming from 401 refresh
    if (window.history.state?.expired) {
      addToast(
        MESSAGE_STATUS.ERROR,
        t(['errors.sessionExpired', 'errors.genericErrorMessage', ''])
      )
    }
  }, [addToast, t])

  const onBackClick = (formik) => {
    setType('')
    setId('')
    formik.setFieldValue('id', '')
  }

  const createValidationSchema = () => {
    switch (type) {
      case 'phone':
        return Yup.string()
          .matches(phoneRegex)
          .required(t(['forms.requiredField', '']))
      case 'email':
        return Yup.string()
          .email(' ')
          .required(t(['forms.requiredField', '']))
      default:
        return Yup.string().required(t(['forms.requiredField', '']))
    }
  }

  const validationSchema = Yup.object({
    id: createValidationSchema(),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    authService
      .otpLogin(values.id, type)
      .then((res) => {
        setSubmitting(false)
        history.push({
          pathname: ROUTES.OTP,
          state: { otpChannels: res.data?.otpChannels },
        })
      })
      .catch((e) => {
        setSubmitting(false)

        let errorMessage = t('errors.genericErrorMessage', '')
        if (e.response) {
          const { data } = e.response
          const injectValues = {}

          if (data?.details?.unlocksInMins) {
            injectValues['unlocksInMins'] = data?.details?.unlocksInMins
          }
          errorMessage = data
            ? t(getErrorKeys('errors', data), injectValues)
            : t(['otpLogin.errorMessage', 'errors.genericErrorMessage', ''])
        }

        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  if (auth && isLoggedIn(auth)) {
    history.push(ROUTES.DASHBOARD)
  } else {
    refreshCache([LOCAL_STORAGE_KEYS.CONFIG, LOCAL_STORAGE_KEYS.REDIRECT_URL])
  }

  const renderCaption = (formik) => {
    if (!type) {
      return t(['otpLogin.caption', ''])
    } else {
      return formik.isSubmitting
        ? t(['otpLogin.loadingCaption', ''])
        : t([`otpLogin.${type}Caption`, ''])
    }
  }

  useEffect(() => {
    if (id && type) buttonRef.current.click()
  }, [id, type])

  return (
    <React.Fragment>
      <StyledWrapper align="center" margin="125px auto">
        <StyledGrid halign="center">
          <StyledColumn
            size={{ md: 4 / 8, lg: 3 / 12 }}
            halign="center"
            direction="column"
          >
            <Formik
              initialValues={{
                id: id || '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <div>
                  <Heading
                    dangerouslySetInnerHTML={{
                      __html: t('otpLogin.heading', ''),
                    }}
                  />
                  <Caption
                    dangerouslySetInnerHTML={{
                      __html: renderCaption(formik),
                    }}
                  />
                  {type ? (
                    <StyledForm
                      onSubmit={formik.handleSubmit}
                      style={{
                        display: formik.isSubmitting ? 'none' : 'block',
                      }}
                    >
                      <InputFieldContainer>
                        {type === 'phone' ? (
                          <StyledPhoneInput>
                            <PhoneInput
                              country={countryCode}
                              value={id}
                              enableSearch
                              onChange={(phone) =>
                                formik.setFieldValue('id', '+' + phone)
                              }
                            />
                          </StyledPhoneInput>
                        ) : (
                          <InputField
                            id="id"
                            label={t([`otpLogin.${type}Label`, ''])}
                            type="text"
                            formik={formik}
                            className="otp-email-username"
                          />
                        )}
                      </InputFieldContainer>
                      <div>
                        <StyledFormButton
                          ref={buttonRef}
                          type="submit"
                          id="submit"
                          disabled={!formik.isValid}
                        >
                          {formik.isSubmitting ? (
                            <Loader />
                          ) : (
                            <span>{t('otpLogin.otpLoginButton', '')}</span>
                          )}
                        </StyledFormButton>
                      </div>
                    </StyledForm>
                  ) : (
                    <StyledFlexColumn
                      margin="40px 0 0 0"
                      align="normal"
                      justify="center"
                    >
                      {config?.otpLoginMethods['email']?.visible && (
                        <IconButton
                          text={t('otpLogin.emailButton', '')}
                          icon={<TbMail size={24} />}
                          onClick={() => setType(OTPLoginMethods['e'])}
                        />
                      )}
                      {config?.otpLoginMethods['phone']?.visible && (
                        <IconButton
                          text={t('otpLogin.phoneButton', '')}
                          icon={<FiPhone size={24} />}
                          onClick={() => setType(OTPLoginMethods['p'])}
                        />
                      )}
                      {config?.otpLoginMethods['username']?.visible && (
                        <IconButton
                          text={t('otpLogin.usernameButton', '')}
                          icon={<HiOutlineUser size={24} />}
                          onClick={() => setType(OTPLoginMethods['u'])}
                        />
                      )}
                    </StyledFlexColumn>
                  )}
                  {formik.isSubmitting && (
                    <StyledFlexColumn margin="100px">
                      <RotatingLines
                        strokeColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue(
                          '--pages-mainContainer-primaryTextColor'
                        )}
                        strokeWidth="3"
                        animationDuration="0.75"
                        width="64"
                        visible={true}
                      />
                    </StyledFlexColumn>
                  )}
                  {type && !formik.isSubmitting && (
                    <LinkButton
                      className="buttons-linkColor"
                      margin="40px 0 0 0"
                      onClick={() => onBackClick(formik)}
                    >
                      {t('otpLogin.backButton', '')}
                    </LinkButton>
                  )}
                </div>
              )}
            </Formik>
          </StyledColumn>
        </StyledGrid>
      </StyledWrapper>
    </React.Fragment>
  )
}

export default withTranslation()(OtpLogin)
