import React from 'react'
import styled from 'styled-components'
import { TFunction, withTranslation } from 'react-i18next'

const FooterContainer = styled.div`
  background-color: var(--pages-footer-backgroundColor);
  color: var(--pages-footer-textColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  font-size: 11px;

  > div {
    margin: 16px auto;
    @media (max-width: 500px) {
      margin: 0 auto 12px;
      text-align: center;

      &:first-of-type {
        margin-top: 12px;
      }
    }

    @media (min-width: 501px) {
      flex: 1;
      display: flex;
      justify-content: center;
      //flex-direction: column;

      &:first-of-type {
        justify-content: flex-start;
      }

      &:last-of-type {
        justify-content: flex-end;
      }
    }
  }

  padding: 0 ${(props) => props.theme.gutters.container.sm}px;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 0 ${(props) => props.theme.gutters.container.md}px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 0 ${(props) => props.theme.gutters.container.lg}px;
  }
`

interface FooterProps {
  t: TFunction
}

const Footer: React.FC<FooterProps> = (props) => {
  const { t } = props

  const footerLeft = t('footer.left', '')
  const footerCenter = t('footer.center', '')
  const footerRight = t('footer.right', '')

  return (
    <FooterContainer id="footer">
      {footerLeft && (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: footerLeft,
            }}
          />
        </div>
      )}
      {footerCenter && (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: footerCenter,
            }}
          />
        </div>
      )}
      {footerRight && (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: footerRight,
            }}
          />
        </div>
      )}
    </FooterContainer>
  )
}

export default withTranslation()(Footer)
