export const ROUTES = {
  LOGIN: '/login',
  OTP_CODE: '/otp/code',
  OTP: '/otp',
  REGISTER_CODE: '/register/code',
  REGISTER_NEW_PSSW: '/register/new-password',
  REGISTER: '/register',
  CHANGE_PSSW: '/change-password',
  FORGOT_PSSW: '/forgot-password',
  DASHBOARD: '/dashboard',
  CALLBACK: '/callback/:pathId',
  HOME: '/',
  HISTORY: '/history',
  RESET_PSSW: '/reset-password',
  LINK_PAGE: '/l/:id',
}

export const SERVICES = {
  CHANGE_PSSW: '/me/password/change',
  DEACTIVATE: '/me/deactivate',
  FORGOT_PSSW: '/auth/forgot-password',
  LOGIN: '/auth/login',
  PASSWORDLESS_LOGIN: '/auth/otp-login',
  OTP: '/auth/otp',
  SEND_OTP: '/auth/send-otp',
  NEW_PSSW: '/me/password',
  REFRESH_TOKEN: '/me/refresh',
  LOGOUT: '/me/logout',
  REGISTER: '/register',
  REGISTER_CODE: '/register/verify',
  RESEND_CODE: '/register/resend-code',
  UI_CONFIG: '/uiconfig',
  USER: '/me',
  USER_OPTIONS: '/me/options',
  POST_USER_OPTIONS: '/me/actions',
  PAGINATED_HISTORY: '/me/actions/history',
  HISTORY: '/me/actions/history',
  REDIRECT_LINK: '/magic-links',
  CACHE: '/me/cache',
  PAGE_VIEWS: '/analytics/page-views',
  INTEGRATION_SERVICES: '/integration-services',
}

// TODO use env variables as per base url
const BRAND = 'cssng'

// TODO Implement TTL functionality
export const LOCAL_STORAGE_KEYS = {
  TEMP_AUTH: BRAND + '-temp-auth',
  AUTH: BRAND + '-auth',
  LANG: BRAND + '-lang',
  CONFIG: BRAND + '-config',
  USER: BRAND + '-user',
  USER_OPTIONS: BRAND + '-user-options',
  HISTORY: BRAND + '-history',
  CACHE: BRAND + '-cache',
  DEVICE_ID: BRAND + '-device-id',
  REDIRECT_URL: BRAND + '-redirect-url',
}

export const STATUS_CODES = {
  SUCCESS: 200,
  VALIDATION_ERROR: 400,
  UNAUTHORIZED: 401,
  FORBIDEN: 403,
  NOT_FOUND: 404,
}

export const DEFAULT_LANGUAGE = 'en-US'
export const LANGUAGE_LABELS = {
  en_US: 'English (US)',
  en_GB: 'English (UK)',
  el_GR: 'Ελληνικά',
  pt_PT: 'Português',
}
export const VERIFICATION_CODE_EXPIRATION = 180 //in seconds

//export const BASE_URL = 'https://unogroz4ik.execute-api.eu-north-1.amazonaws.com' //dev env
//export const BASE_URL = 'https://3w981ww49l.execute-api.eu-north-1.amazonaws.com' //qa env
//export const BASE_URL = 'https://1c4eyouxb1.execute-api.eu-west-2.amazonaws.com' //next qa
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

export const LIST_TYPES = {
  DROPDOWN: 'dropdown',
  RADIO_GROUP: 'radioButtons',
}

export const validationTypes = {
  1: 'string',
  2: 'bool',
  3: 'array',
  4: 'date',
  5: 'number',
  6: 'number',
}

export const OTPLoginMethods = {
  e: 'email',
  p: 'phone',
  u: 'username',
}

export const REDIRECT_TARGET = {
  SELF: '_self',
  BLANK: '_blank',
}
