import React, { useEffect } from 'react'
import styled from 'styled-components'
import Dataform from './Dataform/index'
import PtpForm from './PtpForm'
import actionCodes from '../context/actionCodes'
import { processForm, createYupSchema } from '../helpers/form'
import { UIService, AnalyticsTag } from '../services'

const StyledHtmlContainer = styled.div`
  font-size: 14px;
  margin: 12px 0 0;
  &:first-child {
    margin: 0;
  }

  a {
    color: var(--buttons-hypertextLinkColor);

    &:hover {
      color: var(--buttons-hypertextLinkHoverColor);
    }
  }

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  li {
    line-height: 24px;
  }

  b {
    font-weight: 500;
  }

  hr {
    margin: 24px 0;
  }

  @media (max-width: 500px) {
    ul {
      padding-left: 20px;
      li {
        line-height: 18px;
      }
    }
  }
`

interface OptionsProps {
  errorMessages: string[]
  items: Item[]
  formikRef: React.MutableRefObject<{}>
  stepId: number
  caseId: string
  flowId: string
  flowInstanceId: string
  randomJourneyId: number
  analyticsTag: AnalyticsTag
}

interface Field {
  dateType: string
  description: string
  format: string
  hidden: boolean
  maxDate: string | null
  minDate: string | null
  name: string
  readOnly: boolean
  required: boolean
  time: boolean
  title: string
  type: string
  value: string
}

interface Group {
  description: string
  fields: Field[]
  title: string
}

interface Item {
  code: string
  description: string
  type: string
  body: string
  form: {
    groups: Group[]
    formId: string
    title: string
    type: string
  }
  amountTitle: string
  dateTitle: string
  minDate: string
  maxDate: string
  minAmount: string
  proposedAmount: string
  proposedDate: string
  title: string
  currencySymbol: string
  amountDescription: string
  dateDescription: string
}

const Options: React.FC<OptionsProps> = (props) => {
  const {
    errorMessages,
    caseId,
    flowId,
    flowInstanceId,
    analyticsTag,
    randomJourneyId,
  } = props

  // send analyticsTag for the step
  useEffect(() => {
    let isMounted = true
    if (analyticsTag && isMounted) {
      UIService.postPageView(analyticsTag, randomJourneyId)
    }
    return () => {
      isMounted = false
    }
  }, [analyticsTag, randomJourneyId])

  return (
    <>
      {props.items &&
        props.items.map((item: Item, idx: number) => {
          if (item.code) {
            actionCodes.addCode(caseId, item.code, '')
          }
          switch (item.type) {
            case 'form':
              const { initialValues = [], yupSchemeData = [] } =
                item.form.groups.reduce(
                  (obj, group) =>
                    processForm(
                      item.code,
                      flowId,
                      obj,
                      group,
                      caseId,
                      flowInstanceId,
                      errorMessages
                    ),
                  {
                    initialValues: {},
                    yupSchemeData: [],
                  }
                )

              const yupSchema =
                yupSchemeData.reduce(
                  (schema: any, config: any) => createYupSchema(schema, config),
                  {}
                ) || null

              return (
                <React.Fragment key={props.stepId + '-' + idx}>
                  <Dataform
                    {...item}
                    formikRef={props.formikRef}
                    initialValues={initialValues}
                    caseId={caseId}
                    flowInstanceId={flowInstanceId}
                    flowId={flowId}
                    yupSchema={yupSchema}
                    shouldCache={true}
                  />
                </React.Fragment>
              )
            case 'html':
              return (
                <StyledHtmlContainer key={props.stepId + '-' + idx}>
                  <div
                    className="html-option"
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  />
                </StyledHtmlContainer>
              )
            case 'ptp':
              return (
                <React.Fragment key={props.stepId + '-' + idx}>
                  <PtpForm
                    {...item}
                    caseId={caseId}
                    flowId={flowId}
                    flowInstanceId={flowInstanceId}
                    formikRef={props.formikRef}
                  />
                </React.Fragment>
              )
            case 'history':
              const value = actionCodes.replaceValue(caseId, item.body)
              //UIService.postUserHistory(value)
              return (
                <div
                  key={props.stepId + '-' + idx}
                  dangerouslySetInnerHTML={{ __html: value }}
                ></div>
              )
            default:
              return null
          }
        })}
    </>
  )
}

export default Options
