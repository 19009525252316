import React from 'react'
import styled from 'styled-components'
import { Button, LinkButton } from '../components/commonStyles'

const StyledButton = styled(Button)`
  margin: 0;
  min-width: 84px;

  @media (max-width: ${(props) => props.theme.breakpoints.md - 1}px) {
    width: 100%;
    margin-top: 20px;
    min-height: 40px;
  }
`

const ModalSection = styled.section`
  position: fixed;
  background: white;
  width: 640px;
  height: 167px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 600px) {
    width: 320px;
    height: 230px;
  }
`

const ModalContainer = styled.div`
  position: fixed;
  padding: 20.3px 15px 21px 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`

export const ModalTitle = styled.p`
  margin: 20.3px 26.8px 13.7px 23.8px;
  font-family: Roboto;
  font-size: 16px;
  height: 13px;
  font-weight: 500;
  color: #0f0f0f;
`
export const ModalSubtitle = styled.p`
  margin: 17.7px 20px 27px 23.8px;
  font-family: Roboto;
  font-size: 14px;
  color: #0f0f0f;
  width: 451px;
  height: 35px;
  @media only screen and (max-width: 600px) {
    width: 300px;
    height: 50px;
  }
`

export const ModalLoadButton = styled(StyledButton)`
  width: 153px;
  height: 33px;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 15px 21px 0px;
  padding: 0px 20px;
  border-radius: 4px;
  background-color: var(--buttons-backgroundColor);
  color: var(--buttons-textColor);
  font-family: Roboto;
  font-size: 14px;
  @media only screen and (max-width: 600px) {
    width: 130px;
    height: 20px !important;
    float: right;
    margin-top: 3em;
  }

  &:hover {
    background-color: var(--buttons-backgroundHoverColor);
    color: var(--buttons-textHoverColor);
  }
`

export const ClearData = styled(LinkButton)`
  width: 134px;
  height: 13px;
  margin: 8px 8px 7px 26.8px;
  font-family: Roboto;
  font-size: 14px;
  color: var(--pages-mainContainer-primaryTextColor);
  float: right;
  @media only screen and (max-width: 600px) {
    width: 150px;
    height: 20px;
    float: left;
    margin-top: 2em;
    margin-left: 0;
  }
`

export const LastModifiedLabel = styled.p`
  margin: 12px 2px 0 24px;
  font-family: Roboto;
  font-size: 11px;
  font-style: italic;
  text-align: left;
  width: 140px;
  float: left;
`

const Modal = ({ show, children }) => {
  return (
    <ModalContainer style={{ display: show ? 'block' : 'none' }}>
      <ModalSection>{children}</ModalSection>
    </ModalContainer>
  )
}

export default Modal
