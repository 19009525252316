import React from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Dashboard from './Dashboard'

interface Props {
  t: TFunction
}

const Callback: React.FC<Props> = ({ t }) => {
  const { pathId } = useParams<{ pathId?: string }>()

  return <Dashboard pathId={pathId} />
}

export default withTranslation()(Callback)
