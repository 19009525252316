import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'react-router-dom'
import Grid from 'styled-components-grid'
import LoadingBar from 'react-top-loading-bar'

const GlobalStyle = createGlobalStyle<{ customFont?: string }>`
  body {
    font-family: ${(props) =>
      props?.customFont ?? props.theme.fonts.fontFamily};
    font-style: normal;
    font-weight: 400;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--pages-mainContainer-backgroundColor);
  }

  b {
    font-weight: 500;
  }
`

const InputFieldContainer = styled.div`
  //height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 100px 0 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: 20px;
  }
`

const Button = styled.button.attrs((props: any) => ({
  // interface is needed for the customTheme prop, otherwise it breaks.
  type: props.type || 'button',
  customTheme: props.customTheme || {},
}))`
  background-color: ${(props) =>
    props.customTheme.backgroundColor
      ? props.customTheme.backgroundColor
      : 'var(--buttons-backgroundColor)'};
  color: ${(props) =>
    props.customTheme.textColor
      ? props.customTheme.textColor
      : 'var(--buttons-textColor)'};
  border: 1px solid
    ${(props) =>
      props.customTheme.borderColor
        ? props.customTheme.borderColor
        : 'var(--buttons-borderColor)'};
  box-sizing: border-box;
  border-radius: var(--buttons-borderRadius);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  margin: 30px auto;
  cursor: pointer;
  min-width: 110px;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    background-color: var(--buttons-backgroundHoverColor);
    color: var(--buttons-textHoverColor);
    border-color: var(--buttons-borderHoverColor);
  }

  &:disabled {
    cursor: not-allowed;

    span {
      opacity: 0.5;
    }
  }
`

const StyledFormButton = styled(Button)<{
  margin?: string
  width?: string
}>`
  width: ${(props) => props?.width || '180px'};
  //margin: auto;
  margin: ${(props) => props?.margin || '50px auto 15px'};
  min-height: 44px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: 30px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 28px;
  }
`

const Heading = styled.div`
  color: var(--pages-mainContainer-primaryTextColor);
  font-weight: 300;
  font-size: 38px;
  line-height: 45px;
  //margin: 10px 0;
`

const Caption = styled.div`
  color: var(--pages-mainContainer-secondaryTextColor);
  font-weight: normal;
  font-size: 16px;
  margin: 10px 0;
  padding: 0 20px;
`

const LoginFlowText = styled.span<{
  customTheme?: { primaryTextColor?: string }
}>`
  color: ${(props) =>
    props.customTheme
      ? props.customTheme.primaryTextColor
      : 'var(--pages-mainContainer-primaryTextColor)'};
  background: transparent;
  border: none;
  margin: 10px 0 0 0;
  font-size: 12px;
`

const LinkButton = styled.button.attrs((props: any) => ({
  // interface is needed for the customTheme prop, otherwise it breaks.
  type: props.type || 'button',
  customTheme: props.customTheme || {},
  margin: props.margin,
}))`
  color: ${(props) =>
    props.customTheme
      ? props.customTheme.textColor
      : 'var(--buttons-buttonLinkColor)'};
  background: transparent;
  border: none;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  margin: ${(props) => (props?.margin ? props.margin : '10px 0')};
  &:hover {
    color: var(--buttons-buttonLinkHoverColor);
  }
`

const StyledInput = styled.input`
  color: var(--pages-mainContainer-primaryTextColor);
  border-bottom: solid 1px var(--pages-mainContainer-primaryTextColor);
`

const StyledCounter = styled.div`
  color: var(--pages-mainContainer-primaryTextColor);
  font-size: 9px;
  line-height: 11px;
  font-style: italic;
  font-weight: normal;
  width: 100%;
`

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.error};
  margin: 10px 0;
  display: inline-block;
  width: 100%;
`

const MainContainer = styled.div`
  max-width: ${(props) => props.theme.breakpoints.xxl}px;
  width: 100%;
  margin: 0 auto;
  flex: 1;

  @media (max-width: 500px) {
    margin-top: 60px;
  }
`

const StyledGrid = styled(Grid)`
  width: 100%;
`

const StyledColumn = styled(Grid.Unit)`
  padding: 0 ${(props) => props.theme.gutters.column.sm / 2}px;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 0 ${(props) => props.theme.gutters.column.md / 2}px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 0 ${(props) => props.theme.gutters.column.lg / 2}px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  ${(props) => props.gutter && `padding: ${props.gutter} !important;`}
  ${(props) =>
    props.halign &&
    `
    justify-content: ${props.halign};
    `}
  ${(props) => props.direction && `flex-direction: ${props.direction};`}
`

const StyledWrapper = styled.div<{ margin?: string; align?: string }>`
  margin: ${(props) => (props?.margin ? props.margin : 0)};
  text-align: ${(props) => (props?.align ? props.align : 'left')};
`

const StyledTitleContainer = styled.div`
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
`

const HistoryStyledWrapper = styled(StyledWrapper)`
  margin: 30px auto 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    margin: 56px auto 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 90px auto 0;
  }
`

const StyledHeader = styled.div<{
  customTheme?: { primaryTextColor?: string }
}>`
  color: ${(props) =>
    props.customTheme
      ? props.customTheme.primaryTextColor
      : 'var(--pages-mainContainer-primaryTextColor)'};
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`

const StyledDesc = styled.div<{
  customTheme?: { primaryTextColor?: string }
}>`
  color: ${(props) =>
    props.customTheme
      ? props.customTheme.primaryTextColor
      : 'var(--pages-mainContainer-primaryTextColor)'};
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
`

const StyledNavIcons = styled.div`
  margin: 0px 10px 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: -60px;
    margin-bottom: 30px;
  }
`

const StyledIconButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 8px;
  }

  > div {
    display: flex;
    align-items: center;
  }
  span {
    font-weight: 500;
    font-size: 13px;
  }
`

const ScrollTopButton = styled.div`
  position: fixed;
  left: 80%;
  bottom: 10%;
  z-index: 1;
  cursor: pointer;
  //color: green;
  border: 2px solid #000000;
  background: #f7f7f7;
  padding: 8px;
  border-radius: 4px;
  //display: flex;

  > svg {
    transform: rotate(90deg);
  }
`

const StyledForm = styled.form`
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin: 0 20px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  color: var(--buttons-buttonLinkColor);
  margin-top: 20px;

  &:visited {
    color: var(--buttons-buttonLinkColor);
  }

  &:hover {
    color: var(--buttons-buttonLinkHoverColor);
  }
`

const StyledFlexRow = styled.div<{
  align?: string
  justify?: string
  margin?: string
  padding?: string
}>`
  display: flex;
  flex-flow: row;
  align-items: ${(props) => props?.align || 'center'};
  justify-content: ${(props) => props?.justify || 'center'};
  margin: ${(props) => props?.margin || '0'};
  padding: ${(props) => props?.padding || '0'};
`

const StyledFlexColumn = styled.div<{
  align?: string
  justify?: string
  margin?: string
  padding?: string
}>`
  display: flex;
  flex-flow: column;
  align-items: ${(props) => props?.align || 'center'};
  justify-content: ${(props) => props?.justify || 'center'};
  margin: ${(props) => props?.margin || '0'};
  padding: ${(props) => props?.padding || '0'};
`

const StyledCheckBoxLabel = styled.label<{ fontSize?: string }>`
  font-size: ${(props) => props?.fontSize || '13px'};
  margin: 0 8px;
  color: var(--pages-mainContainer-primaryTextColor);
`

const StyledLoadingBar = styled(LoadingBar)`
  div {
    div {
      margin-left: -1rem;
    }
  }
`

export {
  GlobalStyle,
  Button,
  StyledFormButton,
  ButtonsContainer,
  Heading,
  Caption,
  MainContainer,
  InputFieldContainer,
  LoginFlowText,
  LinkButton,
  StyledInput,
  StyledCounter,
  ErrorMessage,
  StyledColumn,
  StyledWrapper,
  StyledGrid,
  StyledTitleContainer,
  HistoryStyledWrapper,
  StyledHeader,
  StyledDesc,
  StyledNavIcons,
  StyledIconButton,
  ScrollTopButton,
  StyledForm,
  StyledLink,
  StyledFlexColumn,
  StyledFlexRow,
  StyledCheckBoxLabel,
  StyledLoadingBar,
}
