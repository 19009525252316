import { Field, Formik } from 'formik'
import React from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import {
  Caption,
  Heading,
  InputFieldContainer,
  StyledCheckBoxLabel,
  StyledColumn,
  StyledFlexRow,
  StyledFlexColumn,
  StyledForm,
  StyledFormButton,
  StyledGrid,
  StyledWrapper,
  LinkButton,
} from '../components/commonStyles'
import Loader from '../components/Loader'
import * as Yup from 'yup'
import { ROUTES } from '../constants'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { authService } from '../services'
import { MESSAGE_STATUS, useMessages } from '../context/messages'
import { capitalize, getErrorKeys, isLoggedIn } from '../helpers'

interface Props {
  t: TFunction
}

interface MaskedId {
  email?: string
  phone?: string
}

const getAvailableChannels = (otpChannels: MaskedId) => Object.keys(otpChannels)

const StyledMfaCaption = styled(Caption)`
  font-size: 16px;
  color: var(--pages-mainContainer-primaryTextColor);
`

const MfaChannel: React.FC<Props> = ({ t }) => {
  const history = useHistory()
  const { state } = useLocation<{
    otpChannels: MaskedId
  }>()

  const { auth } = authService

  if (auth && isLoggedIn(auth)) {
    history.push(ROUTES.DASHBOARD)
  }

  if (!state) {
    history.push(ROUTES.LOGIN)
  }

  const channels = getAvailableChannels(state.otpChannels)

  const { addToast } = useMessages()

  const validationSchema = Yup.object({
    channel: Yup.string().oneOf(['email', 'phone']),
  })

  const handleSubmit = (
    values: { channel: 'email' | 'phone' },
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    authService
      .sendOtp(values.channel)
      .then(() => {
        setSubmitting(false)
        addToast(MESSAGE_STATUS.SUCCESS, t('mfaChannel.204', ''))
        history.push({
          pathname: ROUTES.OTP_CODE,
          state: { channel: values.channel },
        })
      })
      .catch((err) => {
        setSubmitting(false)
        let errorMessage = t('errors.genericErrorMessage', '')
        if (err.response) {
          const injectValues = {}
          const { data } = err.response

          if (data?.details?.unlocksInMins) {
            injectValues['unlocksInMins'] = data?.details?.unlocksInMins
          }

          errorMessage = data
            ? t(getErrorKeys('errors', data), injectValues)
            : t(['mfaChannel.errorMessage', 'errors.genericErrorMessage', ''])
        }

        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  return (
    <StyledWrapper align="center" margin="125px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 3 / 12 }}
          halign="center"
          direction="column"
        >
          <Heading
            dangerouslySetInnerHTML={{
              __html: t('mfaChannel.heading', ''),
            }}
          />
          <Caption
            dangerouslySetInnerHTML={{
              __html: t('mfaChannel.caption', ''),
            }}
          />
          <Formik
            initialValues={{ channel: channels[0] }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <StyledForm onSubmit={formik.handleSubmit}>
                <InputFieldContainer>
                  {channels?.length > 1 ? (
                    <React.Fragment>
                      <StyledMfaCaption
                        dangerouslySetInnerHTML={{
                          __html: t('mfaChannel.channelSelectionText', ''),
                        }}
                      />
                      <div role="group" aria-labelledby="channel-radio-group">
                        <StyledFlexColumn padding="8px" align="start">
                          {channels?.map((channel) => (
                            <StyledFlexRow padding="6px 8px" key={channel}>
                              <Field
                                type="checkbox"
                                name="channel"
                                value={channel}
                                style={{
                                  accentColor:
                                    'var(--pages-mainContainer-primaryTextColor)',
                                }}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'channel',
                                    e.target.value
                                  )
                                }}
                                checked={formik.values.channel === channel}
                              />
                              <StyledCheckBoxLabel fontSize="16px">
                                {t([`mfaChannel.${channel}Label`, ''], {
                                  [`masked${capitalize(channel)}`]:
                                    state?.otpChannels?.[channel],
                                })}
                              </StyledCheckBoxLabel>
                            </StyledFlexRow>
                          ))}
                        </StyledFlexColumn>
                      </div>
                    </React.Fragment>
                  ) : (
                    <StyledMfaCaption
                      dangerouslySetInnerHTML={{
                        __html: t(['mfaChannel.noSelectionText', ''], {
                          maskedPhoneOrEmail: state?.otpChannels?.[channels[0]],
                        }),
                      }}
                    />
                  )}
                </InputFieldContainer>

                <div>
                  <StyledFormButton
                    type="submit"
                    id="submit"
                    disabled={!formik.isValid}
                  >
                    {formik.isSubmitting ? (
                      <Loader />
                    ) : (
                      <span>{t('mfaChannel.getOtpButton', '')}</span>
                    )}
                  </StyledFormButton>
                </div>
              </StyledForm>
            )}
          </Formik>

          <Link to={ROUTES.LOGIN}>
            <LinkButton
              id="register-btn"
              className="buttons-linkColor"
              margin="4px 0"
            >
              {t('mfaChannel.backButton', '')}
            </LinkButton>
          </Link>
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(MfaChannel)
