import styled from 'styled-components'
import { Button } from '../components/commonStyles'

const StyledCard = styled.div<{
  backgroundColor?: string
  borderColor?: string
  primaryTextColor?: string
  customTheme?: {
    backgroundColor?: string
    borderColor?: string
    shadow?: boolean
  }
}>`
  background: ${(props) =>
    props.customTheme
      ? props.customTheme.backgroundColor
      : 'var(--cards-body-backgroundColor)'};
  border: 1px solid
    ${(props) =>
      props.customTheme
        ? props.customTheme.borderColor
        : 'var(--cards-body-borderColor)'};
  filter: drop-shadow(
    0px 1px 4px
      ${(props) =>
        props.customTheme?.shadow
          ? 'var(--cards-body-shadowColor)'
          : 'transparent'}
  );
  color: var(--pages-mainContainer-primaryTextColor);
  border-radius: var(--cards-body-borderRadius);
  padding: 16px 24px;
  margin: 0 0 30px;
  flex-grow: 1;
  //display: flex;
  height: fit-content;
  //width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin: 0 0 20px;
  }

  .cardbox-header {
    //height: auto;

    @media (max-width: 500px) {
      height: auto !important;
    }
  }
`

const StyledCardTitle = styled.div<{
  customTheme?: {
    primaryTextColor?: string
  }
}>`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};
`

const StyledCardDesc = styled.div<{
  customTheme?: {
    primaryTextColor?: string
  }
}>`
  font-size: 14px;
  line-height: 18px;
  padding-top: 10px;
  margin-bottom: 24px;
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};

  span > a + a {
    margin-left: 8px;
  }

  a {
    color: var(--buttons-hypertextLinkColor);

    &:hover {
      color: var(--buttons-hypertextLinkHoverColor);
    }
  }
`
const StyledCardBoxContainer = styled.div<{
  customTheme?: any
}>`
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};
`

const StyledCardBoxWrapper = styled.div<{
  customTheme?: {
    primaryTextColor?: string
  }
}>`
  display: flex;
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};

  @media (max-width: ${(props) => props.theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }
`

const StyledCardBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`

const StyledCardBoxHeader = styled.div<{
  customTheme?: { primaryTextColor?: string }
}>`
  font-size: 12px;
  line-height: 14px;
  height: 26px;
  min-width: 100px;
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    font-size: 14px;
    height: auto;
  }
`

const StyledCardBoxValue = styled.div<{
  customTheme?: { primaryTextColor?: string }
}>`
  font-size: 30px;
  line-height: 40px;
  padding: 5px 0 0;
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};
`

const StyledCardBoxDesc = styled.div<{
  customTheme?: { primaryTextColor?: string }
}>`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 16px;
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-bottom: 26px;
  }
`

const StyledCardButton = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.md - 1}px) {
    width: 100%;
    margin-top: 20px;
    min-height: 44px;
  }
`

export {
  StyledCard,
  StyledCardTitle,
  StyledCardDesc,
  StyledCardBoxWrapper,
  StyledCardBox,
  StyledCardBoxContainer,
  StyledCardBoxHeader,
  StyledCardBoxValue,
  StyledCardBoxDesc,
  StyledCardButton,
}
