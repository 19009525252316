import React from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { StyledFormButton, StyledFlexRow } from './commonStyles'
import styled from 'styled-components'

interface Props {
  t: TFunction
  text: string
  icon: any
  onClick?: any
}

const StyledIconButtonText = styled.span`
  margin: 0 0 0 12px;
`

const IconButton: React.FC<Props> = ({ t, text, icon, onClick }) => {
  return (
    <React.Fragment>
      <StyledFormButton
        type="button"
        margin={'16px'}
        width={'auto'}
        onClick={onClick}
      >
        <StyledFlexRow justify="start">
          {icon}
          <StyledIconButtonText>{text}</StyledIconButtonText>
        </StyledFlexRow>
      </StyledFormButton>
    </React.Fragment>
  )
}

export default withTranslation()(IconButton)
