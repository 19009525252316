import styled from 'styled-components'

import {
  StyledCard,
  StyledCardTitle,
  StyledCardDesc,
  //StyledCardBoxWrapper,
  StyledCardBoxContainer,
} from './styles'

//import //StyledColumn,
//StyledWrapper,
//StyledGrid,
//'../components/commonStyles'
import CardBox from '../components/Cards/CardBox'
import { ColorsT } from '../theme/types'
import {
  Label,
  Button,
  labelForName,
  buttonForName,
  themeForText,
} from '../helpers'

const StyledHalfCard = styled(StyledCard)`
  width: 100%;
  @media (min-width: 500px) {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 48%;
    //flex: 1;
    height: auto;
  }

  @media (min-width: 500px) {
    &:nth-child(2n) {
      margin-left: 20px;
    }
  }

  .cardbox-header {
    margin-top: 5px;
    height: auto;
  }

  .cardbox-desc {
    margin-top: 8px;
    margin-bottom: 0;
  }
`

interface L200Props {
  labels: Label[]
  buttons: Button[]
  colors: ColorsT
  height: number
  setHeight: React.Dispatch<React.SetStateAction<number>>
  theme: any
}

export default function L200(props: L200Props) {
  const { theme, labels, buttons } = props
  const button1 = buttonForName('button1', buttons)
  const title = labelForName('title', labels)
  const description = labelForName('description', labels)
  const bottomBoxesTitle = labelForName('bottomBoxesTitle', labels)
  const box1Title = labelForName('box1Title', labels)
  const box1Value = labelForName('box1Value', labels)
  const box1Description = labelForName('box1Description', labels)

  return (
    <StyledHalfCard
      className="L200_StyleHalfCard"
      {...props.colors}
      customTheme={theme}
    >
      {title && (
        <StyledCardTitle
          dangerouslySetInnerHTML={{ __html: title }}
          customTheme={themeForText(title, labels)}
        />
      )}
      {/* Option desc */}
      {description && (
        <StyledCardDesc
          dangerouslySetInnerHTML={{ __html: description }}
          customTheme={themeForText(description, labels)}
        />
      )}
      <StyledCardBoxContainer
        customTheme={themeForText(bottomBoxesTitle, labels)}
      >
        {bottomBoxesTitle && (
          <div className="boxes-title">{bottomBoxesTitle}</div>
        )}
        {(box1Title || box1Value || box1Description || button1) && (
          <CardBox
            header={box1Title}
            headerTheme={themeForText(box1Title, labels)}
            value={box1Value}
            valueTheme={themeForText(box1Value, labels)}
            desc={box1Description}
            descTheme={themeForText(box1Description, labels)}
            button={button1}
            height={props.height}
            setHeight={props.setHeight}
          />
        )}
      </StyledCardBoxContainer>
    </StyledHalfCard>
  )
}
