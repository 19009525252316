import React from 'react'
import { ROUTES } from '../constants'

import L0 from './L0'
import L100 from './L100'
import L101 from './L101'
import L102 from './L102'
import L200 from './L200'
import L103 from './L103'

const Layouts = {
  0: L0,
  1: L100,
  2: L101,
  100: L102,
  101: L102,
  3: L200,
  10: L103,
}

let layoutIndex = 0

// eslint-disable-next-line
interface LayoutInterface {
  items: {
    main: [
      {
        colors: {
          backgroundColor: string
        }
      }
    ]
    cards: [{}]
    steps: [{}]
    id: number
  }
}

export const generateLayout = (layout: any, props = {}) => {
  // TODO keep index or use layout.id
  if (typeof Layouts[layout.layoutId] !== 'undefined') {
    return React.createElement(Layouts[layout.layoutId], {
      key: layoutIndex++,
      ...layout,
      ...props,
    })
  }
  // component doesn't exist yet
  // return React.createElement(
  //   () => <div>The component {layout.component} has not been created yet.</div>,
  //   { key: layout._uid }
  // );
}

export const Buttons = {
  showHistory: ROUTES.HISTORY,
  showCards: ROUTES.DASHBOARD,
  showOption: ROUTES.DASHBOARD,
  link: ROUTES.DASHBOARD,
}
