import React from 'react'
import { useStore } from '../context'
import { next } from '../context/actionReducer'
import actionCodes from '../context/actionCodes'
import {
  getToken,
  handleButtonActionTypeClick,
  setToken,
  themeForText,
} from '../helpers'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { useMessages } from '../context/messages'
import Modal, {
  ModalLoadButton,
  ModalSubtitle,
  ModalTitle,
  ClearData,
  LastModifiedLabel,
} from '../components/Modal'
import merge from 'ts-deepmerge'
import { UIService } from '../services'
import { TFunction, withTranslation } from 'react-i18next'
import Loader from '../components/Loader'
import { Label, Button, labelForName, buttonForName } from '../helpers'

import {
  StyledCard,
  StyledCardTitle,
  StyledCardDesc,
  StyledCardBox,
  StyledCardBoxContainer,
  StyledCardButton,
} from './styles'

import {
  StyledColumn,
  //StyledWrapper,
  StyledGrid,
} from '../components/commonStyles'
import { ColorsT } from '../theme/types'
//import CardBox from '../components/Cards/CardBox'

interface L101Props {
  labels: Label[]
  buttons: Button[]
  colors: ColorsT
  theme?: any
  position?: string
  t: TFunction
}

function L101(props: L101Props) {
  const [, dispatch] = useStore()
  const [showModal, setShowModal] = React.useState(false)
  const [cacheIsLoading, setCacheIsLoading] = React.useState(false)
  const [headIsLoading1, setHeadIsLoading1] = React.useState(false)
  const [buttonActionClicked, setButtonActionClicked] = React.useState(0)
  const [headIsLoading2, setHeadIsLoading2] = React.useState(false)
  const [lastModified, setLastModified] = React.useState('')
  const { labels, buttons, theme, position } = props

  const button1 = buttonForName('button1', buttons)
  const button2 = buttonForName('button2', buttons)
  const title = labelForName('title', labels)
  const description = labelForName('description', labels)

  const { addToast } = useMessages()
  const { t } = props

  const initStep1 = button1?.action?.option?.steps
    ? button1?.action?.option?.steps[0]?.id
    : 0
  const step1 = initStep1 || 0
  const { stepItems1 } = button1?.action?.option?.steps
    ? button1.action?.option?.steps.find((st) => st.id === step1).items
    : []

  const initStep2 = button2?.action?.option?.steps
    ? button2?.action?.option?.steps[0]?.id
    : 0
  const step2 = initStep2 || 0
  const { stepItems2 } = button2?.action?.option?.steps
    ? button2.action?.option?.steps.find((st) => st.id === step2).items
    : []

  const loadCache = () => {
    setCacheIsLoading(true)
    const customerActions = actionCodes.getCustomerActions()
    UIService.getCustomerCache(
      customerActions.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    )
      .then((res) => {
        if (res.data) {
          let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
          if (!crnCache) {
            crnCache = {}
          }
          // merge on correct caseId > flowId > flowInstanceId
          let crnFormCache =
            crnCache[customerActions.caseId]?.[customerActions.flowId]?.[
              customerActions.flowInstanceId
            ]
          if (!crnFormCache) {
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: res.data,
                },
              },
            }
          } else {
            const toMerge = merge(crnFormCache, res.data)
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: toMerge,
                },
              },
            }
          }
          setToken(LOCAL_STORAGE_KEYS.CACHE, merge(crnCache, crnFormCache))
        }
      })
      .catch((e) => {
        if (!getToken(LOCAL_STORAGE_KEYS.CACHE)) {
          addToast('error', t('cacheModal.cacheError', ''))
        }
      })
      .finally(() => {
        setCacheIsLoading(false)
        setShowModal(false)
        if (buttonActionClicked === 1) {
          dispatch(next(button1.action))
        } else if (buttonActionClicked === 2) {
          dispatch(next(button2.action))
        }
      })
  }

  const clearCache = () => {
    let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
    if (crnCache) {
      delete crnCache[button1.action.caseId]
      setToken(LOCAL_STORAGE_KEYS.CACHE, crnCache)
    }

    if (buttonActionClicked === 1) {
      if (stepItems1 && stepItems1.length > 0) {
        const codesToClear = stepItems1.map((item) => item.code)
        actionCodes.clearCodes(button1.action.caseId, codesToClear)
      }
    } else if (buttonActionClicked === 2) {
      if (stepItems2 && stepItems2.length > 0) {
        const codesToClear = stepItems2.map((item) => item.code)
        actionCodes.clearCodes(button2.action.caseId, codesToClear)
      }
    }
    const customerActions = actionCodes.getCustomerActions()
    UIService.deleteCustomerCacheForCase(
      button1.action.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    ).finally(() => {
      setShowModal(false)
      if (buttonActionClicked === 1) {
        dispatch(next(button1.action))
      } else if (buttonActionClicked === 2) {
        dispatch(next(button2.action))
      }
    })
  }

  return (
    <StyledCardBox
      style={{ display: 'flex', flexBasis: '100%', marginLeft: '0px' }}
    >
      <Modal show={showModal}>
        <ModalTitle>{t('cacheModal.title', '')}</ModalTitle>
        <ModalSubtitle>{t('cacheModal.subtitle', '')}</ModalSubtitle>
        <div className="row">
          <LastModifiedLabel>
            Information saved {lastModified}
          </LastModifiedLabel>
          <ModalLoadButton onClick={loadCache}>
            {cacheIsLoading ? <Loader /> : t('cacheModal.loadButton', '')}
          </ModalLoadButton>
          <ClearData onClick={clearCache}>
            {t('cacheModal.clearButton', '')}
          </ClearData>
        </div>
      </Modal>
      <StyledCard
        className="L101_StyleCard"
        {...props.colors}
        customTheme={theme}
      >
        {title && (
          <StyledCardTitle
            dangerouslySetInnerHTML={{ __html: title }}
            customTheme={themeForText(title, labels)}
          />
        )}
        {description && (
          <StyledCardDesc
            dangerouslySetInnerHTML={{ __html: description }}
            customTheme={themeForText(description, labels)}
          />
        )}
        <StyledCardBoxContainer>
          <StyledGrid valign="bottom">
            <StyledColumn
              gutter="0"
              halign={position === 'side' ? 'flex-start' : 'flex-end'}
            >
              {button1 && (
                <StyledCardButton
                  className=""
                  onClick={() =>
                    handleButtonActionTypeClick(
                      button1,
                      dispatch,
                      setHeadIsLoading1,
                      setLastModified,
                      setShowModal,
                      addToast,
                      t,
                      1,
                      setButtonActionClicked
                    )
                  }
                  customTheme={button1.theme}
                >
                  {headIsLoading1 ? <Loader /> : button1.text}
                </StyledCardButton>
              )}
              {button2 && (
                <StyledCardButton
                  className=""
                  onClick={() =>
                    handleButtonActionTypeClick(
                      button1,
                      dispatch,
                      setHeadIsLoading2,
                      setLastModified,
                      setShowModal,
                      addToast,
                      t,
                      2,
                      setButtonActionClicked,
                      button2
                    )
                  }
                  customTheme={button2.theme}
                >
                  {headIsLoading2 ? <Loader /> : button2.text}
                </StyledCardButton>
              )}
            </StyledColumn>
          </StyledGrid>
        </StyledCardBoxContainer>
      </StyledCard>
    </StyledCardBox>
  )
}

export default withTranslation()(L101)
