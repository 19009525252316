import React from 'react'
import { ROUTES } from '../constants'
import {
  StyledColumn,
  StyledGrid,
  StyledWrapper,
  StyledLink,
} from '../components/commonStyles'
import { TFunction, withTranslation } from 'react-i18next'

interface NotFoundProps {
  t: TFunction
}

const NotFound: React.FC<NotFoundProps> = (props) => {
  const { t } = props

  return (
    <StyledWrapper align="center" margin="195px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 5 / 12 }}
          halign="center"
          direction="column"
        >
          <div id="not-found">
            <h1
              dangerouslySetInnerHTML={{
                __html: t('errors.heading', ''),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('errors.description', ''),
              }}
            />
            <StyledLink to={ROUTES.HOME}>
              {t('errors.linkToHome', '')}
            </StyledLink>
          </div>
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(NotFound)
