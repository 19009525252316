import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { TFunction, withTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { authService } from '../services'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { ROUTES } from '../constants'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import {
  StyledFormButton,
  Heading,
  Caption,
  InputFieldContainer,
  //ErrorMessage,
  StyledColumn,
  StyledGrid,
  StyledWrapper,
  StyledForm,
  LinkButton,
  LoginFlowText,
  StyledFlexColumn,
} from '../components/commonStyles'
import { getErrorKeys } from '../helpers'
import { AxiosError, AxiosResponse } from 'axios'

interface RegisterProps {
  t: TFunction
}

interface Values {
  username: string
}

const Register: React.FC<RegisterProps> = (props) => {
  //const [message, setMessage] = React.useState('')

  const history = useHistory()
  const { t } = props
  const { addToast } = useMessages()

  const validationSchema = Yup.object({
    username: Yup.string().required(t(['forms.requiredField', ''])),
  })

  const handleSubmit = (
    values: Values,
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    authService
      .register(values.username)
      .then((resp) => {
        setSubmitting(false)
        addToast(MESSAGE_STATUS.SUCCESS, t(['registration.200', '']))
        history.push({
          pathname: ROUTES.REGISTER_CODE,
          state: {
            username: values.username,
            maskedPhone: resp?.data?.maskedPhone || '',
          },
        })
      })
      .catch((e: AxiosError) => {
        setSubmitting(false)

        let errorMessage = t('errors.genericErrorMessage', '')
        if (e.response) {
          const { data }: AxiosResponse = e.response
          const injectValues = {}

          if (data?.details?.unlocksInMins) {
            injectValues['unlocksInMins'] = data?.details?.unlocksInMins
          }
          errorMessage = data
            ? t(getErrorKeys('errors', data), injectValues)
            : t(['errors.genericErrorMessage', ''])
        }

        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  return (
    <StyledWrapper align="center" margin="125px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 3 / 12 }}
          halign="center"
          direction="column"
        >
          <Heading
            dangerouslySetInnerHTML={{
              __html: t('registration.heading', ''),
            }}
          />
          <Caption
            dangerouslySetInnerHTML={{
              __html: t('registration.caption', ''),
            }}
          />
          <Formik
            initialValues={{ username: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <StyledForm onSubmit={formik.handleSubmit}>
                <InputFieldContainer>
                  <InputField
                    id="username"
                    label={t('registration.usernameLabel', '')}
                    type="text"
                    formik={formik}
                  />
                </InputFieldContainer>
                <div>
                  <StyledFormButton
                    type="submit"
                    id="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {formik.isSubmitting ? (
                      <Loader />
                    ) : (
                      <span>{t('registration.verificationButton', '')}</span>
                    )}
                  </StyledFormButton>
                  <Caption>{t('registration.bottomText', '')}</Caption>
                </div>
              </StyledForm>
            )}
          </Formik>

          <StyledFlexColumn>
            <LoginFlowText>{t('registration.loginText', '')}</LoginFlowText>
            <Link to={ROUTES.LOGIN}>
              <LinkButton
                id="login-btn"
                className="buttons-linkColor"
                margin="4px 0"
              >
                {t('registration.loginButton', '')}
              </LinkButton>
            </Link>
          </StyledFlexColumn>
          {/* {message && <ErrorMessage>{message}</ErrorMessage>} */}
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(Register)
